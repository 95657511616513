<template>
    <vs-popup :title="title" :active.sync="show">
        <vs-input
            type="text"
            v-model="form.code"
            label="Code"
            class="mb-3 w-full"
            :danger="errors.code"
            :danger-text="errors.code"
        />
        <vs-input
            type="text"
            v-model="form.description"
            class="mb-3 w-full"
            label="Description"
            :danger="errors.description"
            :danger-text="errors.description"
        />

        <vs-input
            type="number"
            v-model="form.discount"
            label="Discount"
            class="mb-3 w-full"
            :danger="errors.discount"
            :danger-text="errors.discount"
        />

        <vs-input
            v-if="form.id"
            type="number"
            v-model="form.used_count"
            label="Used Count"
            class="mb-3 w-full"
            :danger="errors.used_count"
            :danger-text="errors.used_count"
        />

        <vs-divider />
        <div class="flex justify-end">
            <vs-button
                :disabled="!validForm"
                v-on:click="submit"
                color="primary"
                class="mr-3"
                >{{ buttonText }}
            </vs-button>
            <vs-button v-on:click="$emit('close')" type="flat"
                >Cancel</vs-button
            >
        </div>
    </vs-popup>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'CreateEditQuickCodeModal',
    mixins: [],
    props: ['showData', 'codeData'],
    watch: {
        codeData: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.form.id = value.id
                    this.form.code = value.code
                    this.form.description = value.description
                    this.form.discount = value.discount
                    this.form.used_count = value.used_count
                } else {
                    this.clearForm()
                }
            },
        },
    },
    components: {},
    computed: {
        show: {
            get() {
                return this.showData
            },
            set(value) {
                this.clearForm()
                this.clearErrors()
                this.$emit('close')
            },
        },
        title() {
            return this.form.id ? 'Edit' : 'Create'
        },
        buttonText() {
            return this.form.id ? 'Update' : 'Save'
        },
        validForm() {
            return !!(
                this.form.code &&
                this.form.description &&
                this.form.used_count !== null &&
                this.form.discount !== null
            )
        },
    },
    data() {
        return {
            form: {
                id: null,
                code: null,
                description: null,
                discount: 100,
                used_count: 0,
            },
            errors: {
                code: null,
                description: null,
                discount: null,
                used_count: null,
            },
        }
    },
    methods: {
        submit() {
            if (!this.validForm) return
            if (this.form.id) this.patch()
            else this.post()
        },
        clearForm() {
            this.form.id = null
            this.form.code = null
            this.form.description = null
            this.form.discount = 100
            this.form.used_count = 0
        },
        handleErrors(errors) {
            if (errors.code) this.errors.code = errors.code[0]
            if (errors.description)
                this.errors.description = errors.description[0]
            if (errors.discount) this.errors.discount = errors.discount[0]
            if (errors.used_count) this.errors.used_count = errors.used_count[0]
        },
        clearErrors() {
            this.errors.code = null
            this.errors.discount = null
            this.errors.description = null
            this.errors.used_count = null
        },
        patch() {
            this.clearErrors()
            console.log('patch')
            http.patch('quick_code_detail', this.form)
                .then((response) => {
                    console.log(response.data)
                    this.$emit('update')
                    this.$emit('close')
                    this.clearForm()
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Code Updated',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-edit',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.handleErrors(error.response.data)
                })
        },
        post() {
            this.clearErrors()
            console.log('post')
            http.post('quick_code_detail', this.form)
                .then((response) => {
                    console.log(response.data)
                    this.$emit('update')
                    this.$emit('close')
                    this.clearForm()
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Code Created',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-plus',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.handleErrors(error.response.data)
                })
        },
    },
}
</script>
