<template>
    <div id="data-list-list-view" class="data-list-container" v-if="codes">
        <QuickCodesAllTable
            :data="codes"
            :itemsPerPage="itemsPerPage"
            @itemsPerPage="setItemsPerPage"
            @search="search"
            @filter="setFilter"
            @deleteSingle="deleteConfirm"
            @edit="setEdit"
            @new="setNew"
        ></QuickCodesAllTable>
        <div class="paginator-container">
            <vs-pagination
                description
                goto
                descriptionTitle="Codes"
                :sizeArray="codes.count"
                :description-items="descriptionItems"
                :total="codes.number_of_pages"
                :max-items="itemsPerPage"
                :max="itemsPerPage"
                v-model="pageNumberComputed"
                @changeMaxItems="setItemsPerPage"
            ></vs-pagination>
        </div>
        <CreateEditQuickCodeModal
            :show-data="show.createEditModal"
            :code-data="selectedCode"
            @close="closeCreateEditModal"
            @update="getPage"
        />
    </div>
</template>

<script>
import QuickCodesAllTable from './Table'
import { http } from '@/services'
import CreateEditQuickCodeModal from '@/views/main/QuickCodes/CreateEditModal'

export default {
    name: 'QuickCodes',
    components: {
        QuickCodesAllTable,
        CreateEditQuickCodeModal,
    },
    created() {
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    watch: {
        pageNumber(newValue, oldValue) {
            if (Number(newValue) !== Number(oldValue))
                this.$router.push({
                    name: 'quickCodes',
                    params: { page: Number(newValue) },
                })
        },
    },
    computed: {
        pageNumberComputed: {
            set(value) {
                this.pageNumber = value
                this.getPage()
            },
            get() {
                return this.pageNumber
            },
        },
    },
    data() {
        return {
            codes: null,
            pageNumber: 1,
            itemsPerPage: 10,
            selected: null,
            queriedItems: 20,
            searchQuery: null,
            filter: 'all',
            descriptionItems: [5, 10, 20, 50],
            selectedCode: null,
            show: {
                createEditModal: false,
                info1: true,
            },
        }
    },
    methods: {
        setItemsPerPage(value) {
            this.itemsPerPage = this.descriptionItems[value]
            this.getPage()
        },
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        setView(value) {
            this.searchQuery = null
            this.view = value
            this.getPage()
        },
        getPage() {
            http.get('quick_code_list', {
                params: {
                    filter: this.filter,
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: this.searchQuery,
                    view: this.view,
                },
            })
                .then((response) => {
                    this.codes = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.searchQuery = value
            this.getPage()
        },
        setFilter(value) {
            this.filter = value
            this.getPage()
        },
        deleteConfirm(code) {
            console.log(code)
            this.selectedCode = code
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: `Delete Access Code`,
                text: `${this.selectedCode.code}`,
                accept: this.deleteCode,
                acceptText: 'Delete',
            })
        },
        deleteCode() {
            http.delete('quick_code_detail', {
                params: { id: this.selectedCode.id },
            })
                .then((response) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Code Deleted',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-trash',
                        position: 'top-center',
                    })
                    this.getPage()
                    this.selectedCode = null
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Error',
                        text: error.response.data,
                        color: 'warning',
                        icon: 'feather',
                        iconPack: 'icon-trash',
                        position: 'top-center',
                    })
                    this.selectedCode = null
                })
        },
        setEdit(value) {
            this.selectedCode = value
            this.show.createEditModal = true
        },
        setNew() {
            this.selectedCode = null
            this.show.createEditModal = true
        },
        closeCreateEditModal() {
            this.show.createEditModal = false
            this.selectedCode = null
        },
    },
}
</script>
