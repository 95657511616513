<template>
    <vs-table
        :onlyClickCheckbox="true"
        ref="table"
        v-model="selected"
        :sst="true"
        :data="codes"
    >
        <div slot="header" class="flex items-center flex-grow justify-between">
            <div>
                <vs-button @click="$emit('new')" class="mr-3">Create</vs-button>
            </div>

            <div>
                <vs-input icon="search" placeholder="Search" v-model="search" />
            </div>
        </div>

        <template slot="thead">
            <vs-th>Code</vs-th>
            <vs-th>Description</vs-th>
            <vs-th>Discount</vs-th>
            <vs-th>Used Count</vs-th>
            <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="code" :key="index" v-for="(code, index) in data">
                    <vs-td>
                        {{ code.code }}
                    </vs-td>

                    <vs-td>
                        {{ code.description }}
                    </vs-td>

                    <vs-td> {{ code.discount }}% </vs-td>

                    <vs-td>
                        {{ code.used_count }}
                    </vs-td>

                    <vs-td>
                        <div class="flex justify-end row-hidden-elements">
                            <vs-button
                                type="flat"
                                icon-pack="feather"
                                icon="icon-edit"
                                @click="$emit('edit', code)"
                            ></vs-button>
                            <vs-button
                                color="warning"
                                type="flat"
                                icon-pack="feather"
                                icon="icon-trash"
                                @click="$emit('deleteSingle', code)"
                            ></vs-button>
                        </div>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'QuickCodesAllTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        codes() {
            return this.data.data
        },
        search: {
            set(value) {
                if (value.length > 1 || value === '') {
                    this.searchQuery = value
                    this.$emit('search', value)
                }
            },
            get() {
                return this.searchQuery
            },
        },
    },
    data() {
        return {
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
        }
    },
    methods: {
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        setFilter(status, value) {
            this.status = status
            this.$emit('filter', value)
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
